"use client";
import React from "react";
import Cookies from "js-cookie";
import CustomerSession from "@/Core/Shared/CustomerSession/CustomerSession";
import WishlistApiService from "@/Core/Marketplace/Wishlist/Application/WishlistApiService";
import GetNumProductsCart from "./hooks/GetNumProductsCart";
import UserApiService from "@/Core/Shared/User/Application/UserApiService";
import UserConnectif from "@/Core/Authorization/Vendors/Domain/UserConnectif";

interface Props {
  lang: string;
  currentUrl: string;
  sessionId: string;
  pathOrigin: string;
}

interface State {
  user: UserConnectif;
}

export default class ClientSideLayout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      user: UserConnectif.default(),
    };

    if (
      this.props.currentUrl !== "https://recovo.co" &&
      this.props.currentUrl !== "https://recovo.co/"
    ) {
      Cookies.set("language", this.props.lang);
      Cookies.set("lang", this.props.lang);
    }
  }

  componentDidMount() {
    GetNumProductsCart();
    const redirect = Cookies.get("redirect");
    const currentUrl = window.location.href;
    if (redirect && !currentUrl.includes("login")) {
      Cookies.remove("redirect");
      window.location.href = redirect;
    } else {
      this.fetchUserData().catch((error) => {
        console.error(error);
      });
    }
    if (!currentUrl.includes("header") && !currentUrl.includes("footer")) {
      if (!currentUrl.includes("p/shop")) {
        this.twillio();
      }
      this.connectif();
      this.doofinder();
    }
    CustomerSession.initializeCustomerSession(
      this.props.sessionId,
      this.props.lang,
      this.props.pathOrigin
    );

    // * All of this is to send the event to GTM
    if (typeof window !== "undefined") {
      const userId = Cookies.get("userId");
      const isLoged = Cookies.get("auth_token") ? "logged" : "no logged";
      const language = Cookies.get("language") || "en";

      // Ensure window.dataLayer is defined
      window.dataLayer = window.dataLayer || [];

      window.dataLayer.push({
        user_newsletter: "", // Indica si el usuario está suscrito a la Newsletter
        user_customer: "", // Indica si el usuario ha comprado anteriormente
        login_status: isLoged,
        user_id: userId,
        language: language,
        page_category: "",
      });
    }
    // * End of GTM event
  }

  private twillio() {
    const script = document.createElement("script");
    script.src = "//code.tidio.co/tts4k3nqw44azwfbwwrt2wethaiwfij4.js";
    script.setAttribute("data-namespace", "twillio");
    script.async = true;
    document.body.appendChild(script);
  }

  private connectif() {
    const script = document.createElement("script");
    script.id = "__cn_generic_script__081abf78-07de-4726-a31a-62b6c2cf9857";
    script.innerHTML = `!function(e){function t(){if(!e.querySelector("#__cn_client_script_081abf78-07de-4726-a31a-62b6c2cf9857")){var t=e.createElement("script");t.setAttribute("src","https://cdn.connectif.cloud/eu5/client-script/081abf78-07de-4726-a31a-62b6c2cf9857"),e.body.appendChild(t)}}"complete"===e.readyState||"interactive"===e.readyState?t():e.addEventListener("DOMContentLoaded",t)}(document);`;
    script.async = true;
    document.body.appendChild(script);
  }

  private doofinder() {
    const script = document.createElement("script");
    script.src =
      "//cdn.doofinder.com/recommendations/js/doofinderRecommendation.min.js";
    script.async = true;
    document.body.appendChild(script);
  }

  private async fetchUserData() {
    let user_id = Cookies.get("user_id");
    const auth_token = Cookies.get("auth_token");
    if (auth_token) {
      let userId: number;
      if (!user_id || isNaN(Number(user_id))) {
        userId = await UserApiService.getUserId();
      } else {
        userId = parseInt(user_id);
      }
      // WishlistApiService.getWishlistItemsCount(userId);
      // CartApiService.getCartItemsCount(userId);

      this.setState({
        // user: UserConnectif.create(await UserApiService.getUserConnectifData()),
      });
    } else {
      let cart = Cookies.get("tkn_cart");
      if (!cart) {
        cart = (Math.random() * 1e32).toString(16);
        Cookies.set("tkn_cart", cart, {
          domain: "recovo.co",
          expires: 365,
        });
      }
    }
  }

  render() {
    const { user } = this.state;

    return (
      <>
        <div className="cn_page_tag" style={{ display: "none" }}></div>
        <div className="cn_client_info" style={{ display: "none" }}>
          <span className="primary_key">{user.email}</span>
          <span className="id">{user.id}</span>
          <span className="_name">{user.name}</span>
          <span className="_email">{user.email}</span>
          <span className="_surname">{user.surname}</span>
          <span className="nombre-empresa">{user.companyName}</span>
          <span className="idioma">{user.country}</span>
          <span className="idioma-desde-custom">{user.lang}</span>
          <span className="rol-en-recovo">{user.role}</span>
          <span className="idioma-desde-custom">{user.lang}</span>
          <span className="_totalPurchasesAmount">
            {user.amountTotalOrders}
          </span>
          <span className="_averagePurchasesAmount">{user.avoOrders}</span>
          <span className="_totalPurchases">{user.countOrders}</span>
          <span className="_firstPurchaseDate">{user.firstOrderDate}</span>
          <span className="_lastPurchaseDate">{user.lastOrderDate}</span>
          <span className="_mobilePhone">{user.phone}</span>
          <span className="_createdAt">{user.createdAt}</span>
        </div>
      </>
    );
  }
}

import HeaderMenuElement from "@/Core/Marketplace/Header/Domain/HeaderMenuElement";
import Image from "next/image";
import ArrowLeft from "../../Assets/Images/arrow-left.svg";
import HeaderSubheaderDataMobile from "./HeaderSubheaderDataMobile";

interface Props {
  title: string;
  Subheader: HeaderMenuElement[];
  setSubheaderOpened: (opened: number) => void;
  lang: string;
}

const HeaderSubSubheaderMobile: React.FC<Props> = ({
  title,
  Subheader,
  setSubheaderOpened,
  lang,
}) => {
  return (
    <div className="header-subsubheader-mobile">
      <div
        onClick={() => setSubheaderOpened(-1)}
        className="header-subsubheader-mobile-title"
      >
        <Image src={ArrowLeft} height={24} width={24} alt="Go Back" />
        <p>{title}</p>
      </div>
      <HeaderSubheaderDataMobile Data={Subheader} lang={lang} />
    </div>
  );
};

export default HeaderSubSubheaderMobile;

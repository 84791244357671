import Cookies from "js-cookie";

const GetMyAccountUrl = (url: string, userRole?: Number): string => {
  const lang: string = Cookies.get("language") ?? "en";
  if (!userRole) {
    const cookieUserRole = Cookies.get("userRole");
    userRole = cookieUserRole ? parseInt(cookieUserRole) : 0;
  }
  if (userRole === 3 || userRole === 6) {
    return `/${lang}/my-account-vendor/${url}`;
  } else {
    return `/${lang}/my-account/${url}`;
  }
};

export default GetMyAccountUrl;

export default class CartAddress {
  address: string;
  city: string;
  zip: string;
  country: string;

  constructor(address: string, city: string, zip: string, country: string) {
    this.address = address;
    this.city = city;
    this.zip = zip;
    this.country = country;
  }

  public static default(): CartAddress {
    return new CartAddress("", "", "", "");
  }
}

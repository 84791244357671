'use client'

import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

export default class CustomerSession {
    platform: string;
    sessionId: string;
    userId: string | null;
    pathOrigin: string;
    event: string;
    lang: string;

    constructor(
        platform: string,
        sessionId: string,
        userId: string | null,
        pathOrigin: string,
        event: string,
        lang: string
    ) {
        this.platform = platform;
        this.sessionId = sessionId;
        this.userId = userId;
        this.pathOrigin = pathOrigin;
        this.event = event;
        this.lang = lang;
    }
    public static create(
        sessionId: string,
        userId: string | null,
        pathOrigin: string,
        event: string,
        lang: string
    ): CustomerSession {
        return new CustomerSession(
            "custom",
            sessionId,
            userId,
            pathOrigin,
            event,
            lang
        );
    }

    static getSessionId(): string {
        return Cookies.get("session_id") ?? uuidv4();
    }

    static getUserId(): string | null {
        const authToken = Cookies.get("auth_token");
        const userId = Cookies.get("userId");

        return authToken && userId ? userId : null
    }

    static initializeCustomerSession(sessionId: string, lang: string, pathOrigin: string) {
        const customerSession = CustomerSession.create(
            sessionId,
            this.getUserId(),
            pathOrigin,
            "event",
            lang
        );

        Cookies.set('customer_session', JSON.stringify(customerSession));
    }
}

const GetUrlWordpress = (slug: string, lang: string): string => {
  const wordpressURL =
    process.env.NEXT_PUBLIC_MARKETPLACE_URL ?? "https://store.recovo.co";
  if (lang === "en") {
    return `${wordpressURL}/${slug}`;
  }
  return `${wordpressURL}/${lang}/${slug}`;
};

export default GetUrlWordpress;

"use client";
import HeaderApiService from "@/Core/Marketplace/Header/Application/HeaderApiService";

const GetUserRole = async (): Promise<number> => {
  try {
    const user = await HeaderApiService.checkUserLoged();
    if (user) {
      return user.role_id;
    }
    return 0;
  } catch (error) {
    return 0;
  }
};

export default GetUserRole;

import HeaderMenuElement from "./HeaderMenuElement";

export default class HeaderMenu {
  id: string;
  name: string;
  slug: string;
  elements: HeaderMenuElement[];

  constructor(
    id: string,
    name: string,
    slug: string,
    elements: HeaderMenuElement[]
  ) {
    this.id = id;
    this.name = name;
    this.slug = slug;
    this.elements = elements;
  }

  static default(): HeaderMenu {
    return new HeaderMenu("", "", "", []);
  }
}

import { useState, useEffect } from "react";
import SearchbarApiService from "../../../Application/SearchbarApiService";
import RecentSearch from "../../../Domain/RecentSearch";
import t from "@/app/hooks/lang";
import Save from "../Assets/Images/save-icon.svg";
import Saved from "../Assets/Images/saved-icon.svg";
import Image from "next/image";

interface Props {
  setSearchTxt: (search: string) => void;
  searchType: string;
}

const RecentSearches: React.FC<Props> = ({ setSearchTxt, searchType }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [recentSearches, setRecentSearches] = useState<RecentSearch[]>([]);

  const fetchRecentSearches = async () => {
    const searches = await SearchbarApiService.getRecentSearches();
    setRecentSearches(searches);
    setLoading(false);
  };

  const saveSearch = async (id: string): Promise<void> => {
    await SearchbarApiService.saveSearch(id);
    setLoading(true);
    fetchRecentSearches();
  };

  const clickRecentSearch = async (title: string): Promise<void> => {
    setLoading(true);
    setSearchTxt(title);
    await SearchbarApiService.clickSuggestion(title, searchType);
  };

  useEffect(() => {
    fetchRecentSearches();
  }, []);

  return recentSearches.length > 0 ? (
    <div>
      <p className="recent-searches-title">{t("recentSearches")}</p>
      {recentSearches.map((recentSearch: RecentSearch, i: number) => (
        <div key={i} className="searchbar-product">
          <p onClick={() => setSearchTxt(recentSearch.search)}>
            {recentSearch.search?.split("+").join(" ")}
          </p>
          <Image
            onClick={() => saveSearch(recentSearch.id)}
            src={recentSearch.isFavorite ? Saved : Save}
            alt="Save Search"
            width={20}
            height={20}
          />
        </div>
      ))}
    </div>
  ) : loading ? (
    <div className="loading-spinner-small" />
  ) : recentSearches.length > 0 ? (
    <div>
      <p className="recent-searches-title">{t("recentSearches")}</p>
      {recentSearches.map((recentSearch: RecentSearch) => (
        <div key={recentSearch.id} className="searchbar-product">
          <p onClick={() => clickRecentSearch(recentSearch.search)}>
            {recentSearch.search}
          </p>
          <Image
            onClick={() => saveSearch(recentSearch.id)}
            src={recentSearch.isFavorite ? Saved : Save}
            alt="Save Search"
            width={20}
            height={20}
          />
        </div>
      ))}
    </div>
  ) : (
    <p>{t("noRecentSearches")}</p>
  );
};

export default RecentSearches;

import { useState, useEffect } from "react";
import Shop from "../Assets/Images/shop-icon.svg";
import Search from "../Assets/Images/search-icon.svg";
import Close from "../Assets/Images/close-icon.svg";
import Fav from "../Assets/Images/fav-icon.svg";
import User from "../Assets/Images/user-icon.svg";
import NoLogedButtons from "./NoLogedButtons";
import Image from "next/image";
import Cookies from "js-cookie";
import GetWishCount from "@/app/hooks/GetWishCount";
import GetCartCount from "@/app/hooks/GetCartCount";
import GetUserRole from "@/app/hooks/GetUserRole";
import MyAccount from "./MyAccount";
import GetUrlWordpress from "@/app/hooks/GetUrlWordpress";
import CartWidget from "@/Core/Marketplace/Cart/Infrastructure/Ui/Components/CartWidget";

interface Props {
  removeSelectors: () => void;
  showSearchbar: boolean;
  lang: string;
  setShowSearchbar: (show: boolean) => void;
}

const RightOptions: React.FC<Props> = ({
  lang,
  removeSelectors,
  showSearchbar,
  setShowSearchbar,
}) => {
  const [token, setToken] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasMounted, setHasMounted] = useState<boolean>(false);
  const [cartCount, setCartCount] = useState<number>(0);
  const [wishCount, setWishCount] = useState<number>(0);
  const [userRole, setUserRole] = useState<number>(0);
  const [showMyAccount, setShowMyAccount] = useState<boolean>(false);
  const [showCartWidget, setShowCartWidget] = useState<boolean>(false);

  useEffect(() => {
    setHasMounted(true);
    const getRole = async () => {
      const role = await GetUserRole();
      setUserRole(role);
      if (role !== undefined && role !== null) {
        Cookies.set("user_role", role.toString());
      }
      setToken(Cookies.get("auth_token"));
      setIsLoading(false);
    };
    getRole();
  }, []);

  useEffect(() => {
    const checkCookieChange = () => {
      const cart = GetCartCount();
      if (cart !== cartCount) {
        setCartCount(cart);
      }

      const wish = GetWishCount();
      if (wish !== wishCount) {
        setWishCount(wish);
      }

      const newToken = Cookies.get("auth_token");
      if (newToken !== token) {
        setToken(newToken);
      }
    };

    const intervalId = setInterval(checkCookieChange, 1500);

    return () => {
      clearInterval(intervalId);
    };
  }, [cartCount, wishCount]);

  if (!hasMounted) return null; // Ensure nothing renders on the server, preventing hydration mismatch

  return (
    <div onMouseOver={removeSelectors} className="rightOptions-container">
      {showSearchbar ? (
        <Image
          onClick={() => setShowSearchbar(false)}
          src={Close}
          alt="Close"
          width={14}
          height={14}
        />
      ) : (
        <>
          <Image
            onClick={() => setShowSearchbar(true)}
            src={Search}
            alt="Search"
            width={20}
            height={20}
            className="hide-right-searchbar-mobile"
          />
          {token && (
            <Image
              onClick={() => setShowMyAccount(true)}
              className="loged-header-icon"
              src={User}
              alt="User"
              width={15.4}
              height={17.25}
            />
          )}
          <a
            href={GetUrlWordpress("wishlist", lang)}
            className="cart-notification"
          >
            <Image src={Fav} alt="Wishlist" width={20} height={18} />
            {wishCount !== 0 && (
              <div>
                <span>{wishCount}</span>
              </div>
            )}
          </a>
          <div className="relative">
            {showCartWidget && (
              <CartWidget lang={lang} setShowCartWidget={setShowCartWidget} />
            )}
            <div
              onClick={() => setShowCartWidget(!showCartWidget)}
              className="cart-notification"
            >
              <Image src={Shop} alt="Shop" width={20} height={18} />
              {cartCount > 0 && (
                <div>
                  <span>{cartCount}</span>
                </div>
              )}
            </div>
          </div>
          {!token && !isLoading && <NoLogedButtons />}
          {showMyAccount && (
            <MyAccount
              lang={lang}
              userRole={userRole}
              setShowMyAccount={setShowMyAccount}
            />
          )}
        </>
      )}
    </div>
  );
};

export default RightOptions;

import CartAddress from "./CartAddress";

export default class CartShipping {
  id: number;
  name: string;
  price: number;
  deliveryAddress: CartAddress;

  constructor(
    id: number,
    name: string,
    price: number,
    deliveryAddress: CartAddress
  ) {
    this.id = id;
    this.name = name;
    this.price = price;
    this.deliveryAddress = deliveryAddress;
  }

  public static default(): CartShipping {
    return new CartShipping(0, "", 0, CartAddress.default());
  }
}

import Cookies from "js-cookie";

const GetLinkHeader = (
  link: string,
  custom: boolean | undefined,
  lang?: string
): string => {
  lang = lang ?? Cookies.get("language") ?? "en";
  const marketplace: string =
    process.env.NEXT_PUBLIC_MARKETPLACE_URL || "https://store.recovo.co";

  if (link !== "") {
    if (custom) {
      return `/${lang}/${link}`;
    }
    let newLang = `${lang}/`;
    if (lang === "en") {
      newLang = "";
    }
    return `${marketplace}/${newLang}${link}`;
  }
  return "/";
};

export default GetLinkHeader;

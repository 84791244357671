import HeaderData from "../../../../Domain/HeaderData";
import Image from "next/image";
import Plus from "../../Assets/Images/plus-icon.svg";
import Minus from "../../Assets/Images/minus-icon.svg";
import { useState } from "react";
import HeaderSubSubheaderMobile from "./HeaderSubSubheaderMobile";
import HeaderSubheaderDataMobile from "./HeaderSubheaderDataMobile";
import t from "@/app/hooks/lang";
import { useRouter } from "next/navigation";
import HeaderMobileFooter from "./HeaderMobileFooter";
import HeaderMenu from "@/Core/Marketplace/Header/Domain/HeaderMenu";

interface Props {
  Subheader: HeaderMenu;
  Data: HeaderData[];
  lang: string;
}

const HeaderDataMobile: React.FC<Props> = ({ Data, Subheader, lang }) => {
  const router = useRouter();

  const [opened, setOpened] = useState<number>(-1);
  const [loading, setLoading] = useState<number>(-1);
  const [subSubheaderOpened, setSubheaderOpened] = useState<number>(-1);

  const openSubheader = (i: number): void => {
    if (Data[i].subheader) {
      if (i === opened) {
        setOpened(-1);
      } else {
        setOpened(i);
      }
    } else {
      setLoading(i);
      window.location.href = `/${lang}/${Data[i].slug}`;
    }
  };

  return (
    <div className="header-data-mobile-container">
      {Data?.map((data: HeaderData, i: number) => (
        <div key={i}>
          {subSubheaderOpened === -1 && (
            <div
              onClick={() => openSubheader(i)}
              className={`header-data-mobile ${
                opened === i && "header-data-mobile-opened"
              } ${i === Data.length - 1 && "header-data-mobile-last"}`}
            >
              <p>
                {t(data.name, lang).toUpperCase()}
                {/* {data.name === "CIMS" && <sup>TM</sup>} */}
              </p>

              {loading === i ? (
                <div className="loading-spinner-xs" />
              ) : (
                <Image
                  src={opened === i ? Minus : Plus}
                  alt="open/close"
                  height={18}
                  width={18}
                />
              )}
            </div>
          )}

          {opened === i && data.subheader && subSubheaderOpened === -1 && (
            <HeaderSubheaderDataMobile
              Data={data.subheader}
              setSubheaderOpened={setSubheaderOpened}
              lang={lang}
            />
          )}
          {opened === 0 && i === 0 && (
            <HeaderSubheaderDataMobile
              Data={Subheader.elements}
              setSubheaderOpened={setSubheaderOpened}
              lang={lang}
            />
          )}
        </div>
      ))}
      {opened === 0 && subSubheaderOpened !== -1 && (
        <HeaderSubSubheaderMobile
          title={Subheader.elements[subSubheaderOpened].name}
          Subheader={Subheader.elements[subSubheaderOpened].elements}
          setSubheaderOpened={setSubheaderOpened}
          lang={lang}
        />
      )}
      <HeaderMobileFooter />
    </div>
  );
};

export default HeaderDataMobile;

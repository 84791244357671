"use client";
import t from "@/app/hooks/lang";
import LanguagesDefault from "../assets/data/Languages.json";
import LanguagesUpcycling from "../assets/data/LanguagesUpcycling.json";
import Image from "next/image";
import FooterLanguages from "../Domain/FooterLanguages";

interface Props {
  upcycling: boolean;
  Languages: FooterLanguages[];
  lang: string;
}

const FooterSelectedLang: React.FC<Props> = ({ upcycling, lang }) => {
  const Languages = upcycling ? LanguagesUpcycling : LanguagesDefault;
  const langSelected: number = Languages.findIndex(
    (language) => language.code === lang
  )
    ? Languages.findIndex((language) => language.code === lang)
    : 0;

  return (
    <div>
      <Image
        src={Languages[langSelected].flag}
        alt="language flag"
        width={20}
        height={20}
      />
      <p>{t(Languages[langSelected].name, lang)}</p>
    </div>
  );
};

export default FooterSelectedLang;

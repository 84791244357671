import t from "@/app/hooks/lang";
import Cookies from "js-cookie";

const NoLogedButtons = () => {
  const lang: string = Cookies.get("language") ?? "en";
  const eventClick = () => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "click_demo_2",
      });
    }
  };

  return (
    <div className="header-buttons">
      <a href={`/${lang}/login`}>
        <button className="header-signIn">{t("signIn")}</button>
      </a>
      <a
        onClick={eventClick}
        href="https://calendly.com/team-recovo/book-a-demo"
      >
        <button className="header-bookDemo">{t("bookDemo")}</button>
      </a>
    </div>
  );
};

export default NoLogedButtons;

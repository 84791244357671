"use client";
import { useState, useEffect } from "react";
import Logo from "@/app/assets/images/recovo-new-logo.png";
import Shop from "../../Assets/Images/shop-icon.svg";
import Search from "../../Assets/Images/search-icon.svg";
import Fav from "../../Assets/Images/fav-icon.svg";
import Menu from "../../Assets/Images/mobile-menu.svg";
import Close from "../../Assets/Images/close-icon.svg";
import Image from "next/image";
import GetUrlWordpress from "@/app/hooks/GetUrlWordpress";
import CartWidget from "@/Core/Marketplace/Cart/Infrastructure/Ui/Components/CartWidget";
import GetCartCount from "@/app/hooks/GetCartCount";
import GetWishCount from "@/app/hooks/GetWishCount";

interface Props {
  lang: string;
  headerOpened: boolean;
  setShowSearchbar: (show: boolean) => void;
  setHeaderOpened: (show: boolean) => void;
}

const HeaderPrincipalMobile: React.FC<Props> = ({
  headerOpened,
  setShowSearchbar,
  setHeaderOpened,
  lang,
}) => {
  const [showCartWidget, setShowCartWidget] = useState<boolean>(false);
  const [cartCount, setCartCount] = useState<number>(0);
  const [wishCount, setWishCount] = useState<number>(0);

  useEffect(() => {
    const checkCookieChange = () => {
      const cart = GetCartCount();
      if (cart !== cartCount) {
        setCartCount(cart);
      }

      const wish = GetWishCount();
      if (wish !== wishCount) {
        setWishCount(wish);
      }
    };

    const intervalId = setInterval(checkCookieChange, 1500);

    return () => {
      clearInterval(intervalId);
    };
  }, [cartCount, wishCount]);

  return (
    <>
      <div className="row header-container-content">
        <div className="col-3">
          {!headerOpened && (
            <>
              <Image
                key="menu"
                onClick={() => setHeaderOpened(true)}
                src={Menu}
                alt="Menu"
                width={16}
                height={14}
              />
              <Image
                key="search"
                onClick={() => setShowSearchbar(true)}
                src={Search}
                alt="Search"
                width={20}
                height={20}
              />
            </>
          )}
        </div>
        <div className="col-6">
          <a href={`/${lang}`}>
            <Image
              priority
              src={Logo}
              className="recovo-logo-header"
              alt="Recovo Logo"
              width={129}
              height={20}
            />
          </a>
        </div>
        <div className="col-3">
          {!headerOpened ? (
            <>
              <a href={GetUrlWordpress("wishlist", lang)}>
                <Image
                  key="fav"
                  src={Fav}
                  alt="Wishlist"
                  width={20}
                  height={18}
                />
              </a>

              <div
                onClick={() => setShowCartWidget(!showCartWidget)}
                className="cart-notification"
              >
                <Image src={Shop} alt="Shop" width={20} height={18} />
                {cartCount > 0 && (
                  <div>
                    <span>{cartCount}</span>
                  </div>
                )}
              </div>
            </>
          ) : (
            <Image
              key="close"
              onClick={() => setHeaderOpened(false)}
              src={Close}
              alt="Close"
              width={14}
              height={14}
            />
          )}
        </div>
      </div>
      {showCartWidget && (
        <CartWidget lang={lang} setShowCartWidget={setShowCartWidget} />
      )}
    </>
  );
};

export default HeaderPrincipalMobile;

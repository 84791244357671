import { useState, useMemo, useCallback } from "react";
import HeaderPrincipal from "../Components/HeaderPrincipal";
import SubHeader from "./Subheader";
import SubSubHeader from "./SubSubheader";
import HeaderData from "../../../Domain/HeaderData";
import HeaderMenu from "../../../Domain/HeaderMenu";
import HeaderMenuElement from "../../../Domain/HeaderMenuElement";

interface Props {
  Data: HeaderData[];
  Subheader: HeaderMenu;
  lang: string;
}

const HeaderContent: React.FC<Props> = ({ Data, Subheader, lang }) => {
  const [categorySelected, setCategorySelected] = useState<number>(-1);
  const [subheaderSelected, setSubheaderSelected] = useState<number>(-1);

  const getChunkedCategories = useCallback(
    (categories: (HeaderData | HeaderMenuElement)[]): HeaderMenuElement[][] => {
      if (categories) {
        const numColumns = 4;
        const totalItems = categories.length;
        const itemsPerColumn = Math.floor(totalItems / numColumns);
        const remainder = totalItems % numColumns;
        const chunkedCategories: HeaderMenuElement[][] = [];

        let currentIndex = 0;
        for (let i = 0; i < numColumns; i++) {
          const chunkSize = i < remainder ? itemsPerColumn + 1 : itemsPerColumn;
          const chunk = categories.slice(
            currentIndex,
            currentIndex + chunkSize
          ) as HeaderMenuElement[];
          chunkedCategories.push(chunk);
          currentIndex += chunkSize;
        }
        return chunkedCategories;
      } else {
        return [];
      }
    },
    []
  );

  const removeSelectors = useCallback((): void => {
    setCategorySelected(-1);
    setSubheaderSelected(-1);
  }, []);

  const selectCategory = useCallback((i: number): void => {
    setCategorySelected(i);
    setSubheaderSelected(-1);
  }, []);

  const selectSubheader = useCallback((i: number): void => {
    setSubheaderSelected(i);
  }, []);

  const subHeaders = useMemo(() => {
    return Data?.map(
      (data: HeaderData, i: number) =>
        data.subheader &&
        data.subheader.length > 0 && (
          <div
            key={i}
            style={{ display: categorySelected === i ? "block" : "none" }}
          >
            <SubHeader
              Data={data.subheader}
              lang={lang}
              subheaderSelected={subheaderSelected}
              selectSubheader={selectSubheader}
            />
          </div>
        )
    );
  }, [Data, categorySelected, subheaderSelected, selectSubheader]);

  const subSubHeaders = useMemo(() => {
    return Subheader?.elements.map(
      (data: HeaderMenuElement, i: number) =>
        data.elements &&
        data.elements.length > 0 && (
          <div
            key={i}
            style={{
              display:
                subheaderSelected === i && categorySelected === 0
                  ? "block"
                  : "none",
            }}
          >
            <SubSubHeader
              Data={getChunkedCategories(data.elements)}
              setSubheaderSelected={setSubheaderSelected}
              lang={lang}
            />
          </div>
        )
    );
  }, [
    Subheader.elements,
    subheaderSelected,
    categorySelected,
    getChunkedCategories,
  ]);

  return (
    <header onMouseLeave={removeSelectors} className="header-container-princ">
      <HeaderPrincipal
        Data={Data}
        categorySelected={categorySelected}
        selectCategory={selectCategory}
        removeSelectors={removeSelectors}
        lang={lang}
      />
      {subHeaders}
      <div
        className="relative"
        style={{
          display: categorySelected === 0 ? "block" : "none",
          bottom: categorySelected === 0 ? "-20px" : "",
        }}
      >
        <SubHeader
          Data={Subheader.elements}
          lang={lang}
          subheaderSelected={subheaderSelected}
          selectSubheader={selectSubheader}
        />
        <div
          style={{
            display:
              categorySelected >= 0 && subheaderSelected >= 0
                ? "block"
                : "none",
          }}
        >
          {subSubHeaders}
        </div>
      </div>
    </header>
  );
};

export default HeaderContent;

"use client";
import Cookies from "js-cookie";

const GetCartCount = (): number => {
  const cart = Cookies.get("cart");

  if (!cart) {
    return 0;
  }
  const parsedCart = parseInt(cart, 10);

  return !isNaN(parsedCart) ? parsedCart : 0;
};

export default GetCartCount;

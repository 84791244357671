import HeaderData from "../../../../Domain/HeaderData";
import Image from "next/image";
import ArrowRight from "../../Assets/Images/arrow-right.svg";
import React, { useState } from "react";
import t from "@/app/hooks/lang";
import HeaderMenuElement from "@/Core/Marketplace/Header/Domain/HeaderMenuElement";

interface Props {
  Data: HeaderData[] | HeaderMenuElement[];
  setSubheaderOpened?: (opened: number) => void;
  lang: string;
}

const HeaderSubheaderDataMobile: React.FC<Props> = ({
  Data,
  setSubheaderOpened,
  lang,
}) => {
  const marketplace =
    process.env.NEXT_PUBLIC_MARKETPLACE ?? "https://store.recovo.co";

  const [loading, setLoading] = useState<number>(-1);

  const openData = (
    i: number,
    subHeaderData: HeaderData | HeaderMenuElement
  ): void => {
    if ("elements" in subHeaderData) {
      if (subHeaderData.elements.length === 0) {
        setLoading(i);
        window.location.href = `/${lang}/c/shop/${subHeaderData.slug}`;
      } else {
        if (setSubheaderOpened) {
          setSubheaderOpened(i);
        }
      }
    } else {
      setLoading(i);
      if (subHeaderData.custom)
        window.location.href = `/${lang}/${subHeaderData.slug}`;
      else {
        if (lang === "en") {
          window.location.href = `${marketplace}/${subHeaderData.slug}`;
        } else {
          window.location.href = `${marketplace}/${lang}/${subHeaderData.slug}`;
        }
      }
    }
  };

  return Data.map(
    (subHeaderData: HeaderData | HeaderMenuElement, i: number) => (
      <React.Fragment key={i}>
        <div
          onClick={() => openData(i, subHeaderData)}
          className="subheader-data-mobile"
        >
          <p style={subHeaderData.id == "1784" ? { color: "red" } : {}}>
            {t(subHeaderData.name, lang)}
          </p>
          {loading === i ? (
            <div className="loading-spinner-xs" />
          ) : (
            <Image src={ArrowRight} alt="open/close" height={16} width={9} />
          )}
        </div>
      </React.Fragment>
    )
  );
};

export default HeaderSubheaderDataMobile;

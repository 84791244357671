"use client";
import { useState, useEffect } from "react";
import HeaderData from "../../../Domain/HeaderData";
import HeaderContent from "./HeaderContent";
import HeaderContentMobile from "./Mobile/HeaderContentMobile";
import HeaderMenu from "../../../Domain/HeaderMenu";

interface Props {
  Data: HeaderData[];
  Subheader: HeaderMenu;
  lang: string;
  isMobileSSR: boolean;
}

const HeaderDesktopOrMobile: React.FC<Props> = ({
  Data,
  Subheader,
  lang,
  isMobileSSR,
}) => {
  const [isMobile, setIsMobile] = useState<boolean>(isMobileSSR);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1125);
    };

    window.addEventListener("resize", handleResize);
    // Initial check on mount
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile ? (
    <HeaderContentMobile Data={Data} Subheader={Subheader} lang={lang} />
  ) : (
    <HeaderContent Data={Data} Subheader={Subheader} lang={lang} />
  );
};

export default HeaderDesktopOrMobile;

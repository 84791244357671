import Cookies from "js-cookie";
import Image from "next/image";
import User from "../../Assets/Images/user-icon.svg";
import ArrowRight from "@/app/assets/images/arrow-right.svg";
import NoLogedButtons from "../NoLogedButtons";
import GetMyAccountUrl from "@/app/hooks/GetMyAccountUrl";

const HeaderMobileFooter = () => {
  const token: string | undefined = Cookies.get("auth_token");

  if (token) {
    return (
      <div className="header-loged-mobile-container">
        <a href={GetMyAccountUrl("orders")}>
          <div className="header-loged-mobile">
            <div>
              <Image src={User} alt="User" height={20} width={20} />
              <p>My Account</p>
            </div>
            <Image src={ArrowRight} alt="Arrow Right" height={20} width={20} />
          </div>
        </a>
      </div>
    );
  }

  return <NoLogedButtons />;
};

export default HeaderMobileFooter;

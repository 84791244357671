import CartApiService from "@/Core/Marketplace/Cart/Application/CartApiService";
import Cookies from "js-cookie";
import CartContent from "@/Core/Marketplace/Cart/Domain/CartContent";
import CartSuborder from "@/Core/Marketplace/Cart/Domain/CartSuborder";

const GetNumProductsCart = async (cartContent?: CartContent): Promise<void> => {
  try {
    if (!cartContent) {
      cartContent = await CartApiService.getCartContent();
    }

    const numProd: number = cartContent.suborders.reduce(
      (total: number, suborder: CartSuborder) => {
        return total + suborder.items.length;
      },
      0
    );

    Cookies.set("cart", numProd.toString());
  } catch (error) {
    console.error("Failed to get cart content:", error);
  }
};

export default GetNumProductsCart;

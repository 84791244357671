"use client";
import React, { useState } from "react";
import Link from "next/link";
import t from "@/app/hooks/lang";
import SocialMediaData from "../assets/data/SocialMedia.json";
import checkEmail from "@/app/hooks/checkEmail";
import FooterApiService from "../Application/FooterApiService";

interface Props {
  lang: string;
}

interface SocialLink {
  name: string;
  url: string;
  redirect: string;
}

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const SocialMedia: React.FC<Props> = ({ lang }: Props) => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError("");
  };

  const joinNewsletter = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!checkEmail(email)) {
      setError("invalidEmail");
      return;
    }

    try {
      const response = await FooterApiService.subscribeToNewsletter(email);
      if (response.data.contactAction === "created") {
        if (typeof window !== "undefined") {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "newsletter_2",
            success_newsletter: "OK",
          });
        }
        setSuccess(true);
        setError("");

        // Push event to dataLayer after successful subscription
        if (typeof window !== "undefined" && window.dataLayer) {
          window.dataLayer.push({
            event: "new_subscriber",
          });
        }
      } else {
        if (typeof window !== "undefined") {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "newsletter_2",
            success_newsletter: "KO",
          });
        }
        setError("alreadySubscribed");
      }
    } catch (error) {
      setError("Error");
    }
  };

  return (
    <>
      <div className="footer-newsletter">
        <p className="footer-link-title">{t("joinCircular", lang)}</p>
        {success ? (
          <p className="footer-link-txt">{t("thanksSuscribed", lang)}</p>
        ) : (
          <>
            <p className="footer-link-txt">{t("10Off", lang)}</p>
            <form onSubmit={joinNewsletter}>
              <input
                onChange={handleEmailChange}
                type="email"
                placeholder="Email"
                value={email}
              />
              {error && (
                <span className="footer-link-error">{t(error, lang)}</span>
              )}
              <button className="recovo-btn-black">{t("joinNow", lang)}</button>
            </form>
          </>
        )}
      </div>

      <div className={"footer-social-img"}>
        {SocialMediaData.map((social: SocialLink, index: number) => (
          <Link
            key={index}
            href={social.redirect}
            target="_blank"
            rel="nofollow"
          >
            <img width={31} height={31} src={social.url} alt={social.name} />
          </Link>
        ))}
      </div>
    </>
  );
};

export default SocialMedia;

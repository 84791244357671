const FormatPrice = (price: number | undefined): string => {
  if (price === undefined) {
    return "0";
  }
  const priceStr = price.toFixed(2);
  const formatted = priceStr.endsWith(".00") ? priceStr.slice(0, -3) : priceStr;

  return formatted;
};

export default FormatPrice;

import Cookies from "js-cookie";

export default abstract class ApiServiceGlobal {
  static backendEndpoint: string =
    process.env.NEXT_PUBLIC_BACKEND_API ?? "https://backend.recovo.me/api/";

  static backendOldEndpoint: string =
    process.env.NEXT_PUBLIC_BACKEND_ENDPOINT ?? "https://www.recovo.me/api/";

  static customHeader: string = process.env.NEXT_PUBLIC_CUSTOM_HEADER ?? "";

  static getAuthToken(): string | undefined {
    return Cookies.get("auth_token");
  }

  static getCompleteUrl(endpoint: string): string {
    return `${this.backendEndpoint}${endpoint}`;
  }

  static async fetchWithAuthorization(
    endpoint: string,
    options: RequestInit = {}
  ): Promise<Response> {
    const token = this.getAuthToken();
    const headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
      "x-frontend-recovo": this.customHeader,
    };
    const response = await fetch(this.getCompleteUrl(endpoint), {
      ...options,
      headers,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  }

  static async fetchWithoutAuthorization(
    endpoint: string,
    options: RequestInit = {}
  ): Promise<Response> {
    const headers = {
      ...options.headers,
      "x-frontend-recovo": this.customHeader,
    };
    const response = await fetch(this.getCompleteUrl(endpoint), {
      ...options,
      headers,
    });
    if (!response.ok) {
      console.error(endpoint);
      console.error(response);
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  }

  static async fetchOldWithAuthorization(
    endpoint: string,
    options: RequestInit = {}
  ): Promise<Response> {
    const token = this.getAuthToken();
    const headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
      "x-frontend-recovo": this.customHeader,
    };
    const response = await fetch(`${this.backendOldEndpoint}${endpoint}`, {
      ...options,
      headers,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  }

  static async fetchOldWithoutAuthorization(
    endpoint: string,
    options: RequestInit = {}
  ): Promise<Response> {
    const headers = {
      ...options.headers,
      "x-frontend-recovo": this.customHeader,
    };
    const response = await fetch(`${this.backendOldEndpoint}${endpoint}`, {
      ...options,
      headers,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  }

  static async fetchGetRouteInfoByLangAndEntitySlug(
    lang: string,
    entityType: string | null,
    entitySlug: string | null
  ): Promise<any> {
    const langUrl =
      lang == "home" || lang == undefined || lang == ""
        ? Cookies.get("language") ?? "en"
        : lang;
    const response = await this.fetchWithoutAuthorization(
      `routes/${langUrl}/${entityType}/${entitySlug}`
    );
    if (!response.ok) {
      return null;
    }
    const data = await response.json();
    return data.result;
  }
}

import ApiServiceGlobal from "@/Core/Shared/Application/ApiServiceGlobal";
import Cookies from "js-cookie";
import UserConnectif from "@/Core/Authorization/Vendors/Domain/UserConnectif";
import GetUserRequest from "../Domain/GetUserRequest";
import UserBuyerRequest from "../Domain/UserBuyerRequest";
import { useRouter } from "next/navigation";
const lang: string = Cookies.get("language") ?? "en";
const logoutUrl: string = `/${lang}/logout`;

export default class UserApiService extends ApiServiceGlobal {
  static async getUserData(noNeedLoged?: boolean): Promise<GetUserRequest> {
    try {
      const response = await this.fetchWithAuthorization("users");
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error(error);
      if (noNeedLoged) return GetUserRequest.default();
      const router = useRouter();
      router.replace(logoutUrl);
      return GetUserRequest.default();
    }
  }

  static async getUserBuyerData(
    sessionId?: string,
    userId?: string
  ): Promise<UserBuyerRequest> {
    try {
      if (!sessionId) {
        sessionId = Cookies.get("sessionId");
      }
      if (!userId) {
        userId = Cookies.get("userId");
      }
      const response = await this.fetchWithAuthorization("users/buyers", {
        headers: {
          "x-session-id": sessionId ?? "",
          "x-user-id": userId ?? "",
        },
      });
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error(error);
      return UserBuyerRequest.default();
    }
  }

  static async preLogin(
    router: ReturnType<typeof useRouter>,
    token?: string
  ): Promise<void> {
    try {
      if (!token) token = Cookies.get("auth_token");
      if (!token) {
        return;
      }
      const response = await this.fetchWithAuthorization(`user`);
      const data = await response.json();
      if (token) {
        Cookies.set("auth_token", token);
        Cookies.set("user_id", data.id);
        Cookies.set("userId", data.id);
      }
      router.push(`${lang}/my-account/orders`);
    } catch (error) {
      console.error(error);
    }
  }

  static async autologin(
    autologinToken: string,
    email: string
  ): Promise<string> {
    const myEmail = email.replace(/%40/g, "@");
    try {
      const response = await this.fetchOldWithoutAuthorization(
        `auth/autologin-user`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: autologinToken,
            email: myEmail,
          }),
        }
      );
      const data = await response.json();
      await Cookies.set("auth_token", data.access_token);
      await Cookies.set("user_id", data.user_id);
      await Cookies.set("userId", data.user_id);
      return data.redirect_url;
    } catch (error) {
      console.error(error);
      return logoutUrl;
    }
  }

  static async logout(user_id: number): Promise<string> {
    try {
      const response = await this.fetchOldWithAuthorization(
        `auth/logout/${user_id}`
      );
      const data = await response.json();
      return data.redirect_url;
    } catch (error) {
      console.error(error);
      return `/${lang}/login`;
    }
  }

  static async getUserId(): Promise<number> {
    const user_id = Cookies.get("user_id");
    if (!user_id || isNaN(Number(user_id))) {
      const response = await this.fetchWithAuthorization("user");
      const data = await response.json();
      return parseInt(data.id);
    } else {
      return parseInt(user_id);
    }
  }

  static async getUserRole(): Promise<number> {
    const response = await this.fetchWithAuthorization("user");
    const data = await response.json();
    return parseInt(data.role_id);
  }

  static async getUserConnectifData(): Promise<UserConnectif> {
    const response = await this.fetchWithAuthorization(
      `outsourced-services/connectif/users`
    );
    const data = await response.json();
    return data.result;
  }
}

import Cookies from "js-cookie";
import ApiServiceGlobal from "@/Core/Shared/Application/ApiServiceGlobal";
import CartContent from "../Domain/CartContent";
import CartDiscountCodeResponse from "../Domain/CartDiscountCodeResponse";
import CartCalculatedCost from "../Domain/CartCalculatedCost";
import GetNumProductsCart from "@/app/hooks/GetNumProductsCart";

export default class CartApiService extends ApiServiceGlobal {
  static async addProductToCart(
    productId: number,
    quantity: number
  ): Promise<boolean> {
    try {
      const sessionId = Cookies.get("sessionId") ?? "";
      const userId = Cookies.get("user_id") ?? "";
      await this.fetchWithAuthorization(`carts/products/${productId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-session-id": sessionId,
          "x-user-id": userId,
        },
        body: JSON.stringify({ quantity }),
      });

      return true;
    } catch (error) {
      console.error("Error adding product to cart: ", error);
      return false;
    } finally {
      GetNumProductsCart();
    }
  }

  static async getCartContent(
    token?: string,
    userId?: string,
    sessionId?: string,
    lang?: string
  ): Promise<CartContent> {
    try {
      if (!sessionId) sessionId = Cookies.get("sessionId") ?? "";
      if (!userId) userId = Cookies.get("user_id") ?? "";
      if (!lang) lang = Cookies.get("language") ?? "en";
      const response = await this.fetchWithAuthorization("carts", {
        headers: {
          "x-session-id": sessionId,
          "x-user-id": userId,
          lang,
        },
      });
      const data = await response.json();
      GetNumProductsCart(data.result);
      return data.result;
    } catch (error) {
      console.error("Error getting Products: ", error);
      Cookies.remove("cart");
      return CartContent.default();
    }
  }

  static async addToCart(
    productId: number | string,
    quantity: number,
    sessionId: string,
    userId: string | null,
    update?: boolean
  ): Promise<void> {
    try {
      const url = `carts/products/${productId}`;
      const data = { quantity };
      const config = {
        method: update ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Session-Id": sessionId,
          "X-User-Id": userId ?? "",
        },
        body: JSON.stringify(data),
      };

      await this.fetchWithAuthorization(url, config);
    } catch (error) {
      console.error("Error updating/adding product to cart: ", error);
    }
  }

  static async calculateCost(
    countryDestination: string,
    cartId: string
  ): Promise<CartCalculatedCost | null> {
    try {
      const response = await this.fetchWithAuthorization(
        `carts/calculate-costs/${cartId}/${countryDestination}`
      );
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Error calculating cost: ", error);
      return null;
    }
  }

  static async checkDiscountCode(
    code: string,
    cartAmount: number
  ): Promise<CartDiscountCodeResponse> {
    try {
      const response = await this.fetchWithAuthorization(
        `discount-codes/check-available-by-code/${code}?cartAmount=${cartAmount}`
      );
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Error adding discount code: ", error);
      return CartDiscountCodeResponse.default();
    }
  }
}

"use client";
import t from "@/app/hooks/lang";
import FooterLanguages from "../Domain/FooterLanguages";
import { usePathname } from "next/navigation";
import Image from "next/image";
import Cookies from "js-cookie";
import FooterLanguagesUrls from "../Domain/FooterLanguagesUrls";

interface Props {
  Languages: FooterLanguages[];
  langSelected: number;
  alternates?: any;
  lang: string;
  languageUrls: FooterLanguagesUrls;
}

const LanguagesSelector: React.FC<Props> = ({
  Languages,
  langSelected,
  lang,
  languageUrls,
}) => {
  const pathname: string = usePathname();

  const openNewLang = (code: string, e: any): void => {
    e.preventDefault();
    Cookies.set("language", code);
    Cookies.set("lang", code);
    if (pathname === "/") window.location.href = "/" + code;
    else window.location.href = languageUrls[code];
  };

  return (
    <div className="mobileMenu-header-div-selectLang selector-ssr">
      {Languages.map((language: FooterLanguages, i: number) => (
        <a
          onClick={(e) => openNewLang(language.code, e)}
          href={languageUrls[language.code]}
          key={i}
        >
          {i !== langSelected && (
            <div className="mobile-select-header-lang">
              <div className="languages-header-dropdown">
                <Image
                  src={language.flag}
                  alt={language.name}
                  width={20}
                  height={20}
                />
                <p>{t(language.name, lang)}</p>
              </div>
            </div>
          )}
        </a>
      ))}
    </div>
  );
};

export default LanguagesSelector;

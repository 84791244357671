import t from "@/app/hooks/lang";
import GetLinkHeader from "@/app/hooks/GetLinkHeader";
import HeaderMenuElement from "../../../Domain/HeaderMenuElement";
import CapitalizeFirstLetter from "@/app/hooks/CapitalizeFirstLetter";

interface Props {
  Data: HeaderMenuElement[][];
  setSubheaderSelected: (i: number) => void;
  lang: string;
}

const SubSubHeader: React.FC<Props> = ({
  Data,
  setSubheaderSelected,
  lang,
}) => {
  return (
    <div
      onMouseLeave={() => setSubheaderSelected(-1)}
      className="subSubheader-container subsubheader-categories-container"
    >
      <div className="row">
        {Data?.map((subdata: HeaderMenuElement[], i: number) => (
          <div key={i} className="col-3">
            {subdata?.map((subdata: HeaderMenuElement) => (
              <div key={subdata.id} className="subsubheader-categories">
                <a href={GetLinkHeader(`c/shop/${subdata.slug}`, true, lang)}>
                  <p>{CapitalizeFirstLetter(t(subdata.name, lang))}</p>
                </a>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubSubHeader;

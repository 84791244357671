import LeftOptions from "./LeftOptions";
import RightOptions from "./RightOptions";
import HeaderData from "../../../Domain/HeaderData";
import SearchbarContainer from "@/Core/Marketplace/Searchbar/Infrastructure/Ui/Components/SearchbarContainer";
import { useState } from "react";

interface Props {
  Data: HeaderData[];
  categorySelected: number;
  lang: string;
  selectCategory: (i: number) => void;
  removeSelectors: () => void;
}

const HeaderPrincipal: React.FC<Props> = ({
  Data,
  categorySelected,
  lang,
  selectCategory,
  removeSelectors,
}) => {
  const [showSearchbar, setShowSearchbar] = useState<boolean>(false);

  return (
    <div className="header-container">
      <LeftOptions
        Data={Data}
        lang={lang}
        categorySelected={categorySelected}
        selectCategory={selectCategory}
        showSearchbar={showSearchbar}
      />
      {showSearchbar && (
        <SearchbarContainer setShowSearchbar={setShowSearchbar} lang={lang} />
      )}
      <RightOptions
        removeSelectors={removeSelectors}
        lang={lang}
        showSearchbar={showSearchbar}
        setShowSearchbar={setShowSearchbar}
      />
    </div>
  );
};

export default HeaderPrincipal;

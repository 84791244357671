import Link from "next/link";
import t from "@/app/hooks/lang";

interface Props {
  lang: string;
  subtotal: number;
  setShowCartWidget: (showCartWidget: boolean) => void;
}

const CartWidgetFooter = ({ lang, subtotal, setShowCartWidget }: Props) => {
  return (
    <div className="cart-widget-footer">
      <div>
        <p>{t("subtotal", lang)}</p>
        <p>{subtotal.toFixed(2)} €</p>
      </div>
      <div className="row">
        <div className="col-6">
          <Link onClick={() => setShowCartWidget(false)} href={`/${lang}/cart`}>
            <button className="recovo-btn-white">{t("cart")}</button>
          </Link>
        </div>
        <div className="col-6">
          <Link
            onClick={() => setShowCartWidget(false)}
            href={`/${lang}/checkout`}
          >
            <button className="recovo-btn-black">{t("checkout")}</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CartWidgetFooter;

import ApiServiceGlobal from "@/Core/Shared/Application/ApiServiceGlobal";
import Cookies from "js-cookie";
import generateDeviceId from "@/app/hooks/generateDeviceId";
import RecentSearch from "../Domain/RecentSearch";

export default class SearchbarApiService extends ApiServiceGlobal {
  static async getRecentSearches(): Promise<RecentSearch[]> {
    try {
      const authToken = Cookies.get("auth_token");
      let recentSearches: RecentSearch[] = [];

      if (authToken) {
        const response = await this.fetchWithAuthorization(
          "searches/get-resent-searches"
        );
        const data = await response.json();
        recentSearches = data?.result || [];
      } else {
        const device_id = generateDeviceId();
        const response = await this.fetchWithoutAuthorization(
          `searches/get-resent-searches/${device_id}`
        );
        const data = await response.json();
        recentSearches = data?.result || [];
      }

      return recentSearches;
    } catch (error) {
      console.error("Failed to fetch recent searches:", error);
      return [];
    }
  }

  static async saveSearch(id: string): Promise<void> {
    try {
      await this.fetchWithoutAuthorization(
        `searches/update-search-change-favorite/${id}`,
        {
          method: "PUT",
        }
      );
    } catch (error) {
      console.error("Failed to save search:", error);
    }
  }

  static async getSearchedProducts(
    search: string,
    filter: string
  ): Promise<any> {
    try {
      const lang = Cookies.get("language") ?? "en";
      const response = await this.fetchWithoutAuthorization(
        `outsourced-services/doofinder/search-suggestions-and-quick-search-products/${filter}?searchParams=${search}`,
        {
          headers: {
            lang,
          },
        }
      );
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Failed to fetch searched products:", error);
      throw new Error("Failed to fetch searched products");
    }
  }

  static async clickSuggestion(
    suggestion: string,
    filter: string
  ): Promise<void> {
    try {
      const authToken = Cookies.get("auth_token");
      const device_id = generateDeviceId();
      const lang = Cookies.get("language") ?? "en";

      if (authToken) {
        await this.fetchWithAuthorization(
          `outsourced-services/${lang}/doofinder/quick-search-products/${filter}?searchParams=${suggestion}`
        );
      } else {
        await this.fetchWithoutAuthorization(
          `outsourced-services/${lang}/doofinder/quick-search-products/${filter}/${device_id}?searchParams=${suggestion}`
        );
      }
    } catch (error) {
      console.error("Failed to click suggestion:", error);
    }
  }

  static async doofinderQuickSearchProducts(
    search: string,
    filter: string
  ): Promise<void> {
    try {
      const lang: string = Cookies.get("language") ?? "en";
      const device_id = generateDeviceId();
      const authToken = Cookies.get("auth_token");
      const url = `outsourced-services/${lang}/doofinder/quick-search-products/${search}/${device_id}`;

      if (authToken) {
        await this.fetchWithAuthorization(url);
      } else {
        await this.fetchWithoutAuthorization(url);
      }
    } catch (error) {
      console.error("Failed to doofinder quick search products:", error);
    }
  }
}

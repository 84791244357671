import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/assets/css/globals.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/assets/css/modal.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/assets/css/fonts.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/[lang]/login/css/login.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/[lang]/register/assets/css/register.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/[lang]/my-account/[category]/assets/css/myAccount.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/[lang]/loading/[method]/[endpoint]/[token]/assets/css/loading.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/[lang]/update-password/[token]/assets/css/updatePassword.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/bootstrap/dist/css/bootstrap.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/app/ClientSideLayout.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/footer-ssr/[lang]/assets/css/footer.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/footer-ssr/[lang]/assets/css/langSelector.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/footer-ssr/[lang]/assets/images/enisa.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/footer-ssr/[lang]/assets/images/eu.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/footer-ssr/[lang]/assets/images/minus.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/footer-ssr/[lang]/assets/images/payments.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/footer-ssr/[lang]/assets/images/pea.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/footer-ssr/[lang]/assets/images/plus.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/footer-ssr/[lang]/assets/images/sustainable-goals/goal-12.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/footer-ssr/[lang]/assets/images/sustainable-goals/goal-13.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/footer-ssr/[lang]/assets/images/sustainable-goals/goal-17.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/footer-ssr/[lang]/assets/images/sustainable-goals/goal-6.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/footer-ssr/[lang]/assets/images/sustainable-goals/goal-8.svg");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/footer-ssr/[lang]/assets/images/sustainable-goals/goals.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/app/footer-ssr/[lang]/components/selectLang.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/app/footer-ssr/[lang]/components/socialMedia.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/loginRegister/assets/css/loginRegister.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/pagination/assets/css/pagination.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/Core/Marketplace/Header/Infrastructure/Ui/Assets/Css/Header.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/Core/Marketplace/Header/Infrastructure/Ui/Assets/Css/HeaderMobile.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/Core/Marketplace/Header/Infrastructure/Ui/Components/HeaderDesktopOrMobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/Core/Shared/RotativeBanner/Infrastructure/Ui/Components/RotativeBanner.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/Core/Shared/Searchbar/Infraestructure/Ui/Assets/Css/Searchbar.css");

export default class CartDiscountCodeResponse {
  id: string;
  shopId: string | null;
  code: string;
  type: string;
  amount: number;
  minimumAmount: number;
  isAvailable: boolean;
  availableUses: number | null;
  expiresAt: string | null;
  createdAt: string;

  constructor(
    id: string,
    shopId: string | null,
    code: string,
    type: string,
    amount: number,
    minimumAmount: number,
    isAvailable: boolean,
    availableUses: number | null,
    expiresAt: string | null,
    createdAt: string
  ) {
    this.id = id;
    this.shopId = shopId;
    this.code = code;
    this.type = type;
    this.amount = amount;
    this.minimumAmount = minimumAmount;
    this.isAvailable = isAvailable;
    this.availableUses = availableUses;
    this.expiresAt = expiresAt;
    this.createdAt = createdAt;
  }

  static default(): CartDiscountCodeResponse {
    return new CartDiscountCodeResponse(
      "",
      null,
      "",
      "",
      0,
      0,
      false,
      null,
      null,
      ""
    );
  }
}

"use client";
import LanguagesDefault from "../assets/data/Languages.json";
import LanguagesUpcycling from "../assets/data/LanguagesUpcycling.json";
import Image from "next/image";
import ArrowDown from "@/app/assets/images/arrow-down.svg";
import ArrowUp from "@/app/assets/images/arrow-up.svg";
import LanguageSelector from "./languageSelector";
import SelectedLang from "./FooterSelectedLang";
import FooterLanguagesUrls from "../Domain/FooterLanguagesUrls";

interface Props {
  upcycling: boolean;
  lang: string;
  languageUrls: FooterLanguagesUrls;
}

const SelectLang = ({ upcycling, lang, languageUrls }: Props) => {
  const Languages = upcycling ? LanguagesUpcycling : LanguagesDefault;
  const langSelected =
    Languages.find((language) => language.code === lang) ?? Languages[0];
  const langIndex = Languages.indexOf(langSelected);

  return (
    <div className="select-lang-header-container">
      <input type="checkbox" id="selector-ssr" className="hidden-checkbox" />

      <label htmlFor="selector-ssr" className="select-lang-header-div">
        <div className="select-lang-header-selector">
          <SelectedLang
            upcycling={upcycling}
            Languages={Languages}
            lang={lang}
          />
          <div className="arrow-container">
            <Image
              src={ArrowDown}
              alt="Arrow Down"
              width={12}
              height={7}
              className="arrow-down"
            />
            <Image
              src={ArrowUp}
              alt="Arrow Up"
              width={12}
              height={7}
              className="arrow-up"
            />
          </div>
        </div>
      </label>
      <LanguageSelector
        Languages={Languages}
        langSelected={langIndex}
        lang={lang}
        languageUrls={languageUrls}
      />
    </div>
  );
};

export default SelectLang;

"use client";
import HeaderData from "../../../../Domain/HeaderData";
import HeaderPrincipalMobile from "./HeaderPrincipalMobile";
import { useState } from "react";
import SearchbarContainer from "@/Core/Marketplace/Searchbar/Infrastructure/Ui/Components/SearchbarContainer";
import HeaderDataMobile from "./HeaderDataMobile";
import t from "@/app/hooks/lang";
import HeaderMenu from "@/Core/Marketplace/Header/Domain/HeaderMenu";

interface Props {
  Data: HeaderData[];
  Subheader: HeaderMenu;
  lang: string;
}

const HeaderContentMobile: React.FC<Props> = ({ Data, Subheader, lang }) => {
  const [showSearchbar, setShowSearchbar] = useState<boolean>(false);
  const [headerOpened, setHeaderOpened] = useState<boolean>(false);

  return (
    <header className="header-container-princ-mobile">
      {showSearchbar && (
        <div className="row header-container-searchbar-mobile">
          <div className="col-9">
            <SearchbarContainer
              setShowSearchbar={setShowSearchbar}
              lang={lang}
            />
          </div>
          <div className="col-3">
            <p onClick={() => setShowSearchbar(false)}>{t("cancel")}</p>
          </div>
        </div>
      )}
      {!showSearchbar && (
        <HeaderPrincipalMobile
          setShowSearchbar={setShowSearchbar}
          setHeaderOpened={setHeaderOpened}
          headerOpened={headerOpened}
          lang={lang}
        />
      )}
      {headerOpened && (
        <HeaderDataMobile Data={Data} Subheader={Subheader} lang={lang} />
      )}
    </header>
  );
};

export default HeaderContentMobile;

import UserRoleRequest from "./UserRoleRequest";

export default class GetUserRequest {
  id: number;
  name: string;
  email: string;
  roles: UserRoleRequest;
  meta: any[];

  constructor(
    id: number,
    name: string,
    email: string,
    roles: UserRoleRequest,
    meta: any[] = []
  ) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.roles = roles;
    this.meta = meta;
  }

  static default(): GetUserRequest {
    return new GetUserRequest(0, "", "", new UserRoleRequest());
  }
}

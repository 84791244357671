import { useMemo } from "react";
import t from "@/app/hooks/lang";
import GetLinkHeader from "@/app/hooks/GetLinkHeader";
import HeaderData from "../../../Domain/HeaderData";
import HeaderMenuElement from "../../../Domain/HeaderMenuElement";
import CapitalizeFirstLetter from "@/app/hooks/CapitalizeFirstLetter";

interface Props {
  Data: (HeaderData | HeaderMenuElement)[];
  lang: string;
  subheaderSelected: number;
  selectSubheader: (i: number) => void;
}

const Subheader: React.FC<Props> = ({
  Data,
  lang,
  subheaderSelected,
  selectSubheader,
}) => {
  const isHeaderData = (
    data: HeaderData | HeaderMenuElement
  ): data is HeaderData => {
    return (data as HeaderData).custom !== undefined;
  };

  // Memoize the mapped data elements
  const renderedLinks = useMemo(() => {
    return Data.map((data: HeaderData | HeaderMenuElement, i: number) => (
      <a
        key={i}
        onMouseEnter={() => selectSubheader(i)}
        href={
          isHeaderData(data)
            ? GetLinkHeader(data.slug, data.custom, lang)
            : GetLinkHeader(`c/shop/${data.slug}`, true, lang)
        }
      >
        <p
          style={data.id == "1784" ? { color: "red" } : {}}
          className={
            subheaderSelected === i
              ? "selected-subheader"
              : "not-selected-subheader"
          }
        >
          {CapitalizeFirstLetter(t(data.name, lang))}
          {/* {data.name === "CIMS" && <sup>TM</sup>} */}
        </p>
      </a>
    ));
  }, [Data, subheaderSelected, selectSubheader]);

  const subheaderClass = useMemo(() => {
    return `subheader-container ${
      subheaderSelected === -1 ? "header-border-bottom" : ""
    }`;
  }, [subheaderSelected]);

  return <div className={subheaderClass}>{renderedLinks}</div>;
};

export default Subheader;

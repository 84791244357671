import t from "@/app/hooks/lang";
import Close from "../Assets/Images/close-icon.svg";
import Search from "../Assets/Images/search-icon.svg";
import SearchGrey from "../Assets/Images/search-icon-grey.svg";
import Image from "next/image";
import SearchbarApiService from "../../../Application/SearchbarApiService";

interface Props {
  searchTxt: string;
  searchType: string;
  setSearchTxt: (search: string) => void;
  setLoading: (loading: boolean) => void;
  lang: string;
}

const SubheaderContent: React.FC<Props> = ({
  searchTxt,
  setSearchTxt,
  searchType,
  setLoading,
  lang,
}) => {
  const onSearch = (search: string): void => {
    setSearchTxt(search);
  };

  const submitSearch = async (e: any): Promise<void> => {
    e.preventDefault();
    if (searchTxt.length > 0) {
      setLoading(true);
      await SearchbarApiService.doofinderQuickSearchProducts(
        searchTxt,
        searchType
      );
      const newPath = `/${lang}/c/shop?searchParams=${searchTxt}`;
      window.location.href = newPath;
    }
  };

  const removeSearch = (): void => {
    setSearchTxt("");
  };

  return (
    <div className="relative">
      <div
        className={`searchbar-logo-div ${searchTxt.length > 0 && "pointer"}`}
      >
        <Image
          className="searchbar-logo"
          src={searchTxt.length > 0 ? Search : SearchGrey}
          alt="Search"
          width={20}
          height={20}
        />
      </div>
      <form onSubmit={submitSearch}>
        <input
          className="searchbar-input"
          type="text"
          placeholder={t("searchbarPlaceholder", lang)}
          value={searchTxt}
          onChange={(e) => onSearch(e.target.value)}
        />
      </form>
      {searchTxt !== "" && (
        <Image
          onClick={() => removeSearch()}
          className="close-searchbar-logo"
          src={Close}
          alt="Close"
          width={14}
          height={14}
        />
      )}
    </div>
  );
};

export default SubheaderContent;

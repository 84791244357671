import SearchTypes from "../Assets/Data/SearchTypes.json";
import t from "@/app/hooks/lang";
import SearchbarProducts from "./SearchbarProducts";

interface Props {
  searchTxt: string;
  setSearchType: (search: string) => void;
  searchType: string;
  setSearchTxt: (search: string) => void;
}

const SearchbarResults: React.FC<Props> = ({
  searchTxt,
  setSearchTxt,
  setSearchType,
  searchType,
}) => {
  return (
    <div className="predictions-container">
      <div className="predictions-content">
        <div className="predictions-selector">
          <div className="predictions-selector-txt">
            {SearchTypes.map((type: string, i: number) => (
              <div key={i}>
                <span
                  onClick={() => setSearchType(type)}
                  className={searchType === type ? "be-bold" : ""}
                >
                  {t(type).toUpperCase()}
                </span>
              </div>
            ))}
          </div>
          <div className="predictions-selector-txt-hr">
            {SearchTypes.map((type: string, i: number) => (
              <div key={i} className={i === 1 ? "header-hr" : ""}>
                {i === 1 && <hr />}
                <hr
                  className={`${searchType === type ? "hr-black" : ""} ${
                    i === 1 ? "on-demand-hr" : ""
                  }`}
                />
              </div>
            ))}
            <hr className="predictions-selector-last-hr" />
          </div>
        </div>
        <SearchbarProducts
          searchTxt={searchTxt}
          setSearchTxt={setSearchTxt}
          searchType={searchType}
        />
      </div>
    </div>
  );
};

export default SearchbarResults;

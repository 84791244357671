import ApiServiceGlobal from "@/Core/Shared/Application/ApiServiceGlobal";
import HeaderMenu from "../Domain/HeaderMenu";
import Cookies from "js-cookie";

export default class HeaderApiService extends ApiServiceGlobal {
  static async checkUserLoged(): Promise<any> {
    try {
      const loged = Cookies.get("auth_token");
      if (!loged) return null;
      const response = await this.fetchOldWithAuthorization("user");
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error checking user logged status:", error);
      return null;
    }
  }

  static async getCategoryGroups(): Promise<any> {
    try {
      const response = await ApiServiceGlobal.fetchWithoutAuthorization(
        `category-groups/get-by-name/Header`
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching category groups:", error);
      return null;
    }
  }

  static async getCategoryGroupByNameAndLang(lang: string): Promise<any> {
    try {
      const response = await ApiServiceGlobal.fetchWithoutAuthorization(
        `category-groups/get-by-name/Header`,
        {
          headers: {
            lang,
          },
        }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching category group by name and lang:", error);
      return null;
    }
  }

  static async getMenu(lang: string): Promise<HeaderMenu> {
    try {
      const response = await this.fetchWithoutAuthorization(
        `menus/${lang}/6BA8DD52-7A6A-438C-9AEA-4AD52FAEBD37`
      );
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Error fetching menu:", error);
      return HeaderMenu.default();
    }
  }
}

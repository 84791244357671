"use client";
import "../Assets/Css/CartWidget.css";
import "../Assets/Css/Cart.css";
import { useState, useEffect } from "react";
import CartContent from "../../../Domain/CartContent";
import CartApiService from "../../../Application/CartApiService";
import Cookies from "js-cookie";
import CartContentPage from "./CartContent";
("./CartContent");
import CartWidgetFooter from "./CartWidgetFooter";

interface Props {
  lang: string;
  setShowCartWidget: (showCartWidget: boolean) => void;
}

const CartWidget = ({ lang, setShowCartWidget }: Props) => {
  const sessionId: string = Cookies.get("sessionId") ?? "";
  const userId: string = Cookies.get("user_id") ?? "";
  const [loading, setLoading] = useState<boolean>(true);
  const [cartContent, setCartContent] = useState<CartContent>(
    CartContent.default()
  );

  const fetchCartContent = async (): Promise<void> => {
    setLoading(true);
    const content: CartContent = await CartApiService.getCartContent();
    setCartContent(content);
    setLoading(false);
  };

  useEffect(() => {
    fetchCartContent();
  }, [sessionId, userId]);

  return (
    <div className="cart-widget-container">
      <div
        onClick={() => setShowCartWidget(false)}
        className="cart-widget-background"
      />
      <div className="cart-widget">
        {loading ? (
          <div className="loading-spinner-medium" />
        ) : (
          <div>
            <div
              onClick={() => setShowCartWidget(false)}
              className="cart-widget-close"
            >
              X
            </div>
            <CartContentPage
              cartContent={cartContent}
              setCartContent={setCartContent}
              lang={lang}
              inWidget={true}
            />
            {cartContent.suborders.length > 0 && (
              <CartWidgetFooter
                lang={lang}
                subtotal={cartContent.subtotal}
                setShowCartWidget={setShowCartWidget}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CartWidget;

"use client";
import t from "@/app/hooks/lang";
import { useRef, useEffect, useState } from "react";
import "../Assets/Css/RotativeBanner.css";
import Messages from "../Assets/Data/Messages.json";

interface Props {
  lang: string;
}

const RotativeBanner = ({ lang }: Props) => {
  const bannerRef = useRef<HTMLDivElement>(null);
  const [repetitions, setRepetitions] = useState<number>(1);

  useEffect(() => {
    const banner = bannerRef.current;
    if (banner) {
      const bannerWidth = banner.scrollWidth;
      const containerWidth = banner.parentElement?.offsetWidth || 0;

      // * Calculate how many repetitions are needed to fill the banner fully for seamless scrolling
      const necessaryRepetitions = Math.ceil(
        (containerWidth * 5) / bannerWidth
      );
      setRepetitions(necessaryRepetitions);
    }
  }, []);

  // * Repeat the messages as necessary to make the banner wide enough for smooth scrolling
  const repeatedMessages = Array.from(
    { length: repetitions },
    (_, repetitionIndex) =>
      Messages.map((message: string, i: number) => (
        <p
          key={i + repetitionIndex * Messages.length}
          dangerouslySetInnerHTML={{ __html: t(message, lang) }}
        />
      ))
  ).flat();

  return (
    <>
      <div className="rotative-banner-placeholder" />
      <div className="rotative-banner">
        <div ref={bannerRef} className="rotative-content">
          {repeatedMessages}
        </div>
      </div>
    </>
  );
};

export default RotativeBanner;

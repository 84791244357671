import Cookies from "js-cookie";

export default function generateDeviceId() {
  const CHARSET =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const ID_LENGTH = 32;

  const randomString = (length: number, chars: string) => {
    let result = "";
    for (let i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };

  let deviceId = Cookies.get("deviceId");
  let cookieDeviceId = Cookies.get("device_id");

  if (deviceId && !cookieDeviceId) {
    Cookies.set("device_id", deviceId);
  } else if (!deviceId && cookieDeviceId) {
    Cookies.set("device_id", cookieDeviceId);
    deviceId = cookieDeviceId;
  } else if (!deviceId && !cookieDeviceId) {
    deviceId = randomString(ID_LENGTH, CHARSET);
    Cookies.set("deviceId", deviceId);
    Cookies.set("device_id", deviceId);
  }

  return deviceId;
}

import ApiServiceGlobal from "@/Core/Shared/Application/ApiServiceGlobal";

export default class FooterApiService extends ApiServiceGlobal {
  static async subscribeToNewsletter(email: string): Promise<any> {
    const response = await this.fetchOldWithAuthorization(
      `connectif/subscribe-to-newsletter/${email}`
    );
    return response.json();
  }
}

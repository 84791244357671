import RecentSearches from "./SearchbarRecentSearches";
import SearchbarSearchResult from "./SearchbarSearchResult";

interface Props {
  searchTxt: string;
  searchType: string;
  setSearchTxt: (search: string) => void;
}

const SearchbarProducts: React.FC<Props> = ({
  searchTxt,
  searchType,
  setSearchTxt,
}) => {
  return (
    <div className="searchbar-scroll-vertical">
      {searchTxt === "" ? (
        <RecentSearches setSearchTxt={setSearchTxt} searchType={searchType} />
      ) : (
        <SearchbarSearchResult
          searchTxt={searchTxt}
          setSearchTxt={setSearchTxt}
          searchType={searchType}
        />
      )}
    </div>
  );
};

export default SearchbarProducts;

"use client";
import Cookies from "js-cookie";

const GetWishCount = (): number => {
  const wish = Cookies.get("wishlist");

  if (!wish) {
    return 0;
  }

  if (typeof wish === "string") {
    try {
      const parsedWish = JSON.parse(wish);
      if (Array.isArray(parsedWish)) {
        return parsedWish.length;
      }
      return 0;
    } catch (error) {
      return 0;
    }
  } else {
    return 0;
  }
};

export default GetWishCount;

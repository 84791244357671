"use client";
import "../Assets/Css/Searchbar.css";
import SearchbarContent from "./SearchbarContent";
import SearchbarResults from "./SearchbarResults";
import { useState } from "react";
import SearchTypes from "../Assets/Data/SearchTypes.json";

interface Props {
  setShowSearchbar: (show: boolean) => void;
  lang: string;
}

const SearchbarContainer: React.FC<Props> = ({ setShowSearchbar, lang }) => {
  const [searchTxt, setSearchTxt] = useState<string>("");
  const [searchType, setSearchType] = useState<string>(SearchTypes[0]);
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="searchbar-cont">
      <div onClick={() => setShowSearchbar(false)} className="searchbar-fade" />
      <SearchbarContent
        searchTxt={searchTxt}
        searchType={searchType}
        setSearchTxt={setSearchTxt}
        setLoading={setLoading}
        lang={lang}
      />
      <div className="searchabar-content" />
      {loading ? (
        <div className="predictions-container">
          <div className="loading-spinner-medium" />
        </div>
      ) : (
        <SearchbarResults
          searchTxt={searchTxt}
          setSearchTxt={setSearchTxt}
          searchType={searchType}
          setSearchType={setSearchType}
        />
      )}
    </div>
  );
};

export default SearchbarContainer;

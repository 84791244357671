import { useState, useEffect } from "react";
import SearchbarApiService from "../../../Application/SearchbarApiService";
import QuickSearchProducts from "../../../Domain/QuickSearchProducts";
import SearchedProduct from "../../../Domain/SearchedProduct";
import Cookies from "js-cookie";
import t from "@/app/hooks/lang";
import { useRouter } from "next/navigation";
import ProductApiService from "@/Core/Marketplace/Products2/Application/ProductApiService";

interface Props {
  searchTxt: string;
  setSearchTxt: (search: string) => void;
  searchType: string;
}
const SearchbarSearchResult: React.FC<Props> = ({
  searchTxt,
  setSearchTxt,
  searchType,
}) => {
  const lang: string = Cookies.get("language") || "en";
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(true);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [products, setProducts] = useState<QuickSearchProducts | null>(null);
  const [searching, setSearching] = useState<boolean>(false);

  const searchProducts = async (searchTxt: string): Promise<void> => {
    const result = await SearchbarApiService.getSearchedProducts(
      searchTxt,
      searchType
    );
    setSuggestions(result["search-suggestions"]);
    setProducts(result["quick-search-products"]);
    setLoading(false);
  };

  const clickSuggestion = async (i: number): Promise<void> => {
    const search: string = suggestions[i];
    setSearching(true);
    setSearchTxt(search);
    setLoading(true);
    await SearchbarApiService.doofinderQuickSearchProducts(search, searchType);
    window.location.href = `/${lang}/c/shop/?searchParams=${search}`;
  };

  const getHref = (slug: string): string => {
    return `/${lang}/p/shop/${slug}`;
  };

  const newClickProduct = async (
    e: React.MouseEvent<HTMLAnchorElement>,
    product: SearchedProduct
  ): Promise<void> => {
    e.preventDefault();
    await ProductApiService.productNewClick(searchTxt, product.dfid);
    window.location.href = product.link;
  };

  useEffect(() => {
    // This timeout is to avoid making a request for each letter typed
    const delayDebounceFn = setTimeout(() => {
      if (searchTxt && !searching) {
        setLoading(true);
        searchProducts(searchTxt);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTxt, searchType]);

  return (
    <div className="searchbar-search-result-container">
      {loading ? (
        <div className="loading-spinner-medium" />
      ) : (
        <div>
          <div className="searchbar-suggestions">
            {suggestions?.map((suggestion: string, i: number) => (
              <p key={i} onClick={() => clickSuggestion(i)}>
                {suggestion
                  .split("")
                  .map((letter: string, i: number) =>
                    searchTxt.includes(letter) ? (
                      letter
                    ) : (
                      <b key={i}>{letter}</b>
                    )
                  )}
              </p>
            ))}
          </div>
          <div className="prediction-grey-title">
            <p>
              {products?.results.length} {t("products")}
            </p>
          </div>
          <div className="searchbar-search-result">
            {products?.results.map((product: SearchedProduct, i: number) => (
              <a
                key={i}
                href={product.link}
                onClick={(e) => newClickProduct(e, product)}
              >
                <div>
                  <img src={product.image_link} alt={product.title} />
                  <div className="searchbar-search-result-data">
                    <p>
                      {product.title
                        .split("")
                        .map((letter: string, i: number) =>
                          searchTxt.includes(letter) ? (
                            letter
                          ) : (
                            <b key={i}>{letter}</b>
                          )
                        )}
                    </p>
                    <p>
                      {product.price.toFixed(2)}€ {t("perMeter")}
                    </p>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchbarSearchResult;

import CartSuborder from "./CartSuborder";
import CartShipping from "./CartShipping";

export default class CartContent {
  id: string | number;
  sessionId?: string;
  userId?: number;
  suborders: CartSuborder[];
  subtotal: number;
  fees: number;
  taxes: number;
  total: number;
  shippingOption?: CartShipping;

  constructor(
    id: string | number,
    sessionId: string,
    userId: number,
    suborders: CartSuborder[],
    subtotal: number,
    fees: number,
    taxes: number,
    total: number,
    shippingOption?: CartShipping
  ) {
    this.id = id;
    this.sessionId = sessionId;
    this.userId = userId;
    this.suborders = suborders;
    this.subtotal = subtotal;
    this.fees = fees;
    this.taxes = taxes;
    this.total = total;
    this.shippingOption = shippingOption;
  }

  public static default(): CartContent {
    return new CartContent("", "", 0, [], 0, 0, 0, 0, CartShipping.default());
  }
}

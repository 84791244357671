import Logo from "@/app/assets/images/recovo-new-logo.png";
import t from "@/app/hooks/lang";
import Image from "next/image";
import HeaderData from "../../../Domain/HeaderData";
import GetLinkHeader from "@/app/hooks/GetLinkHeader";
import Link from "next/link";

interface Props {
  Data: HeaderData[];
  lang: string;
  categorySelected: number;
  selectCategory: (i: number) => void;
  showSearchbar?: boolean;
}

const LeftOptions: React.FC<Props> = ({
  Data,
  categorySelected,
  selectCategory,
  showSearchbar,
  lang,
}) => {
  return (
    <div className="leftOptions-container">
      <Link href={`/${lang}`}>
        <Image
          src={Logo}
          className="recovo-logo-header"
          alt="Recovo Logo"
          width={129}
          height={20}
        />
      </Link>
      {!showSearchbar && (
        <>
          {Data?.map((data: HeaderData, i: number) =>
            i === 0 ? (
              <a
                key={i}
                onMouseEnter={() => selectCategory(i)}
                href={GetLinkHeader(data.slug, data.custom, lang)}
              >
                <p
                  className={
                    categorySelected === i
                      ? "header-underline"
                      : "header-underline-hover-hover"
                  }
                >
                  {t(data.name, lang).toUpperCase()}
                  {/* {data.name === "CIMS" && <sup>TM</sup>} */}
                </p>
              </a>
            ) : (
              <Link
                key={i}
                onMouseEnter={() => selectCategory(i)}
                href={GetLinkHeader(data.slug, data.custom, lang)}
              >
                <p
                  className={
                    categorySelected === i
                      ? "header-underline"
                      : "header-underline-hover-hover"
                  }
                >
                  {t(data.name, lang).toUpperCase()}
                  {/* {data.name === "CIMS" && <sup>TM</sup>} */}
                </p>
              </Link>
            )
          )}
        </>
      )}
    </div>
  );
};

export default LeftOptions;

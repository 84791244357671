import UserShippingMeta from "./UserShippingMeta";

export default class UserBuyerRequest {
  id: number;
  name: string;
  shippingMeta: UserShippingMeta;
  billingMeta: UserShippingMeta;

  constructor(
    id: number,
    name: string,
    shippingMeta: UserShippingMeta,
    billingMeta: UserShippingMeta
  ) {
    this.id = id;
    this.name = name;
    this.shippingMeta = shippingMeta;
    this.billingMeta = billingMeta;
  }
}

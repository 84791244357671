import t from "@/app/hooks/lang";
import Data from "@/app/[lang]/my-account/[category]/assets/data/selector.json";
import DataVendor from "@/app/[lang]/my-account/[category]/assets/data/selectorVendor.json";
import { useClickOutside } from "@/app/hooks/useClickOutside";
import { useRef } from "react";
import Link from "next/link";
import HeaderMyAccount from "../../../Domain/HeaderMyAccount";
import GetMyAccountUrl from "@/app/hooks/GetMyAccountUrl";

interface Props {
  userRole: number;
  lang: string;
  setShowMyAccount: (show: boolean) => void;
}

const MyAccount: React.FC<Props> = ({ setShowMyAccount, lang, userRole }) => {
  const ref = useRef(null);

  useClickOutside(ref, (): void => {
    setShowMyAccount(false);
  });

  const getData = (): HeaderMyAccount[] => {
    if (userRole === 3) {
      return DataVendor;
    } else {
      return Data;
    }
  };

  return (
    <div className="header-myaccount">
      <ul>
        {getData().map((item: HeaderMyAccount, i: number) => {
          return (
            <li key={i}>
              <Link href={GetMyAccountUrl(item.url, userRole)}>
                <span>{t(item.title, lang)}</span>
              </Link>
            </li>
          );
        })}
      </ul>
      <Link href={`/${lang}/logout`}>
        <button>{t("signOut", lang)}</button>
      </Link>
    </div>
  );
};

export default MyAccount;

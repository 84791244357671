import Cookies from "js-cookie";

export default class UserConnectif {
  id?: number;
  name?: string;
  surname?: string;
  email?: string;
  phone?: string;
  companyName?: string;
  country?: string;
  lang?: string;
  role?: string;
  amountTotalOrders?: number;
  avoOrders?: number;
  countOrders?: number;
  firstOrderDate?: string;
  lastOrderDate?: string;
  createdAt?: string;

  constructor(
    id: number,
    name: string,
    surname: string,
    email: string,
    phone: string,
    companyName: string,
    country: string,
    lang: string,
    role: string,
    amountTotalOrders: number,
    avoOrders: number,
    countOrders: number,
    firstOrderDate: string,
    lastOrderDate: string,
    createdAt: string
  ) {
    this.id = id;
    this.name = name;
    this.surname = surname;
    this.email = email;
    this.phone = phone;
    this.companyName = companyName;
    this.country = country;
    this.lang = lang;
    this.role = role;
    this.amountTotalOrders = amountTotalOrders;
    this.avoOrders = avoOrders;
    this.countOrders = countOrders;
    this.firstOrderDate = firstOrderDate;
    this.lastOrderDate = lastOrderDate;
    this.createdAt = createdAt;
  }
  public static create(userConnectifResponse: any): UserConnectif {
    const article = new UserConnectif(
      userConnectifResponse.id,
      userConnectifResponse.name,
      userConnectifResponse.surname,
      userConnectifResponse.email,
      userConnectifResponse.phone,
      userConnectifResponse.companyName,
      userConnectifResponse.country,
      userConnectifResponse.lang ?? Cookies.get("language") ?? "en",
      userConnectifResponse.role,
      userConnectifResponse.amountTotalOrders,
      userConnectifResponse.avoOrders,
      userConnectifResponse.countOrders,
      userConnectifResponse.firstOrderDate ?? "",
      userConnectifResponse.lastOrderDate ?? "",
      userConnectifResponse.createdAt
    );
    return article;
  }
  public static default(): UserConnectif {
    return new UserConnectif(
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      0,
      0,
      0,
      "",
      "",
      ""
    );
  }
}

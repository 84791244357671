import UserVendorRequest from "./UserVendorRequest";
import UserBuyerRequest from "./UserBuyerRequest";
import UserAdminRequest from "./UserAdminRequest";

export default class UserRoleRequest {
  buyers: UserBuyerRequest[];
  administrators: UserAdminRequest[];
  vendors: UserVendorRequest[];
  superAdmin: any | null;

  constructor(
    buyers: UserBuyerRequest[] = [],
    administrators: UserAdminRequest[] = [],
    vendors: UserVendorRequest[] = [],
    superAdmin: any | null = null
  ) {
    this.buyers = buyers;
    this.administrators = administrators;
    this.vendors = vendors;
    this.superAdmin = superAdmin;
  }
}
